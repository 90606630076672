import { useEffect, useState, useCallback } from "react";
import { Card, CardContent, debounce } from "@mui/material";
import Datatable from "../ui-components/Custom-Data-Table";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowHeightParams,
  GridSortModel,
} from "@mui/x-data-grid";
import InfoIcon from "@mui/icons-material/Info";
import TransactionService from "../services/transaction";
import Toast from "../ui-components/Toaster";
import { Link } from "react-router-dom";
import { AxiosResponse } from "axios";
import { Search, TransactionsType, TransactionsResponse } from "../utils/types";
import { toPascalCase } from "../utils/helpers";
import { currentUserRole } from "../ui-components/Access-Validate";
import { USER_ROLES } from "../utils/constants";


export default function Transactions() {
  const [page, setPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState<number>(100);
  let capturedFilter = localStorage.getItem("capturedFilter");
  let sCapturedFilter = capturedFilter ? JSON.parse(capturedFilter) as Search : null;
  const [filter, setFilter] = useState<Search>(
    sCapturedFilter || {
      searchTerm: "",
      fromDate: null,
      toDate: null,
      merchant: null,
      status: "",
      currency: "",
      applicationName: "ALL",
      customerType: null
    }
  );
  const [sortOrder, setSortOrder] = useState<any>("DESC");
  const [fieldName, setFieldName] = useState<string>("Id");
  const [rowsData, setRowsData] = useState<TransactionsType[]>([]);
  const [userFilter, setUserFilter] = useState<string[]>([]);
  const [capturedStatuses, setCapturedStatuses] = useState<string[]>([]);
  const [toast, setToast] = useState({ open: 1, message: "", type: "success" });
  const [size, setPageSize] = useState(25);

  const getRowHeight = ({ model }: GridRowHeightParams) => {
    return model.customerInfo ? 70 : 46;
  };

  const columns: GridColDef[] = [
    {
      field: "transactionId",
      headerName: "Transaction ID",
      flex: .7,
      renderCell: (params: GridRenderCellParams) => (
        <Link
          to={`/transactions/captured-detail/${params.row.id}`}
          state={filter}
        >
          {params.row.transactionId}
        </Link>
      ),
    },
    {
      field: "account",
      headerName: "Account",
      width: 160,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {params.row.account}
          <br />
          {params.row.cardType && `Channel: ${params.row.cardType}`}
        </>
      ),
    },
    { field: "transactionDate", headerName: "Date & Time", width: 140 },
    { field: "transactionAmount", headerName: "Amount", width: 110 },
    { field: "userId", headerName: "User", flex: .6, sortable: false },
    {
      field: "transactionStatus",
      headerName: "Status",
      width: 100,
      renderCell: (params: GridRenderCellParams) => toPascalCase(params?.value),
      cellClassName: (params: GridCellParams) => {
        return `transactionStatus ${params?.value?.toLowerCase()}`;
      },
    },
    { field: "merchant", headerName: "Merchant", width: 85, sortable: false },
    {
      field: "customerInfo",
      headerName: "Customer",
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {params.row.customerInfo && (
            <>
              <span style={{ fontSize: '12px' }}>
                {`${params.row.customerInfo?.firstName} ${params.row.customerInfo?.lastName}`}
                <br />
                {`${params.row.customerInfo?.email}`}
                <br />
                {params.row.customerInfo?.phoneNumber &&
                  `${params.row.customerInfo?.phoneNumber?.slice(0,2)}XXXXX${params.row.customerInfo?.phoneNumber?.slice(-2)}`
                }
              </span>
            </>
          )}
        </>
      ),
    },
    {
      headerName: "Action",
      field: "actions",
      type: "actions",
      width: 65,
      align: "right",
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Link
          to={`/transactions/captured-detail/${params.row.id}`}
          state={filter}
        >
          <InfoIcon />
        </Link>
      ),
    },
  ];

  const handleSortModelChange = (sortModel: GridSortModel) => {
    setFieldName(sortModel[0]?.field);
    setSortOrder(sortModel[0]?.sort);
  };

  const getCapturedUsers = async () => {
    TransactionService.getCapturedUsers()
      .then((result) => {
        const userIds = result?.data?.data;
        setUserFilter(userIds);
      })
      .catch((error: any) => {
        setToast({
          open: Date.now(),
          message: error?.message,
          type: "error",
        });
      });
  };
  const getCapturedStatuses = async () => {
    TransactionService.getCapturedStatuses()
      .then((result) => {
        const statuses = result?.data?.data;
        setCapturedStatuses(statuses);
      })
      .catch((error: any) => {
        setToast({
          open: Date.now(),
          message: error?.message,
          type: "error",
        });
      });
  };
  const getAllData = useCallback(
    async (
      size: number,
      page: number,
      fieldName: string,
      sortOrder: string,
      search: Search
    ) => {
      try {
        const res: AxiosResponse<TransactionsResponse> =
          await TransactionService.getAllTransaction(
            size,
            page + 1,
            fieldName,
            sortOrder,
            search
          );
        if (res && res.status === 200) {
          setRowsData(res?.data?.data?.records);
          setRowCount(res?.data?.data?.paginationInfo?.totalRecords);
        } else if (res) {
          setToast({
            open: Date.now(),
            message: res?.data.message,
            type: "error",
          });
        }
      } catch (error: any) {
        setToast({
          open: Date.now(),
          message: error?.message,
          type: "error",
        });
      }
    },
    []
  );

    // Debounce the function with a delay of 100ms
  const debouncedGetAllData = useCallback(
    debounce((pageSize, page, fieldName, sortOrder, search) => {
      getAllData(pageSize, page, fieldName, sortOrder, search);
    }, 100), 
    [getAllData]
  );
  
  useEffect(() => {
    localStorage.setItem("capturedFilter", JSON.stringify(filter));
    debouncedGetAllData(size, page, fieldName, sortOrder, filter);
  
    return () => {
      debouncedGetAllData.clear(); // Cleanup debounce on unmount or dependency change
    };
  }, [size, page, fieldName, sortOrder, filter, debouncedGetAllData]);

  useEffect(() => {
    getCapturedUsers();
    getCapturedStatuses();
  }, []);

  return (
    <>
      <Card>
        <CardContent className="table-cardcontent">
          <Datatable
            rows={rowsData || []}
            userFilter={userFilter}
            statuses={capturedStatuses}
            getRowHeight={getRowHeight}
            page={page ? page : 0}
            columns={columns}
            rowCount={rowCount || 0}
            pageSize={size}
            setPageSize={setPageSize}
            onPageChange={(newPage: number) => setPage(newPage)}
            onSortModelChange={handleSortModelChange}
            setPage={setPage}
            searchText={filter}
            setSearchText={setFilter}
            showtoolbar={true}
            showstatus={true}
            showapplication={true}
            datepicker={true}
            showMerchant={true}
            showUser={currentUserRole().includes(USER_ROLES.ADMIN)}
            showExport={true}
            showCustomerType={false}
          />
        </CardContent>
      </Card>
      <Toast open={toast.open} message={toast.message} type={toast.type} />
    </>
  );
}
