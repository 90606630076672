import { useCallback, useEffect, useState } from 'react';
import transactionService from "../services/transaction";
import { AxiosResponse } from "axios";
import { ApiResponse, Search, TransactionData, TransactionsType } from '../utils/types';
import { Link, useLocation } from 'react-router-dom';
import { Card, CardContent, debounce } from '@mui/material';
import Datatable from "../ui-components/Custom-Data-Table";
import Toast from "../ui-components/Toaster";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowHeightParams,
  GridSortModel,
} from "@mui/x-data-grid";
import { toPascalCase } from '../utils/helpers';
import InfoIcon from "@mui/icons-material/Info";

export default function TransactionTokenized() {
  const location = useLocation();
  const [toast, setToast] = useState({ open: 1, message: "", type: "success" });
  const [page, setPage] = useState(0);
  const [size, setPageSize] = useState(10);
  const [fieldName, setFieldName] = useState<string>("Id");
  const [sortOrder, setSortOrder] = useState<any>("DESC");
  const [rowsData, setRowsData] = useState<TransactionsType[]>([]);
  const [rowCount, setRowCount] = useState<number>(50);
  const [filter, setFilter] = useState<Search | any>({
    searchTerm: null,
    fromDate: null,
    toDate: null,
    merchant: null,
    status: "",
    currency: null,
    applicationName: "ALL",
    customerType: location?.state?.customerType ?? "WHOLESALE"
  });

  useEffect(() => {
    if (location.state?.customerType) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        customerType: location.state.customerType,
      }));
    }
  }, [location.state]);

  const getRowHeight = ({ model }: GridRowHeightParams) => {
    return model.customerInfo ? 70 : 46;
  };
  
  const columns: GridColDef[] = [
    {
      field: "transactionId",
      headerName: "Transaction ID",
      flex: .7,
      renderCell: (params: GridRenderCellParams) => (
        <Link 
          to={`/transactions/tokenization/${params.row.id}`}
          state={{ customerType: filter.customerType }} // Pass filter state 
        >
          {params.row.transactionId}
        </Link>
      ),
    },
    {
      field: "account",
      headerName: "Account",
      width: 160,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {params.row.account}
          <br />
          {params.row.cardType && `Channel: ${params.row.cardType}`}
        </>
      ),
    },
    { field: "transactionDate", headerName: "Date & Time", width: 140 },
    { field: "transactionAmount", headerName: "Amount", width: 110 },
    { field: "userId", headerName: "User", flex: .6, sortable: false },
    {
      field: "transactionStatus",
      headerName: "Status",
      width: 100,
      renderCell: (params: GridRenderCellParams) => toPascalCase(params?.value),
      cellClassName: (params: GridCellParams) => {
        return `transactionStatus ${params?.value?.toLowerCase()}`;
      },
    },
    { field: "merchant", headerName: "Merchant", width: 85, sortable: false },
    {
      field: "customerInfo",
      headerName: "Customer",
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {params.row.customerInfo && (
            <>
              <span style={{ fontSize: '12px' }}>
                {`${params.row.customerInfo?.firstName} ${params.row.customerInfo?.lastName}`}
                <br />
                {`${params.row.customerInfo?.email}`}
                <br />
                {params.row.customerInfo?.phoneNumber &&
                  `${params.row.customerInfo?.phoneNumber?.slice(0,2)}XXXXX${params.row.customerInfo?.phoneNumber?.slice(-2)}`}
              </span>
            </>
          )}
        </>
      ),
    },
    {
      headerName: "Action",
      field: "actions",
      type: "actions",
      width: 65,
      align: "right",
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Link
          to={`/transactions/captured-detail/${params.row.id}`}
          state={{ customerType: filter.customerType }} // Pass filter state
        >
          <InfoIcon />
        </Link>
      ),
    },
  ];

  const handleSortModelChange = (sortModel: GridSortModel) => {
    setFieldName(sortModel[0]?.field);
    setSortOrder(sortModel[0]?.sort);
  };

  const getAllData = useCallback(
    async (size: number, page: number, fieldName: string, sortOrder: string, search: Search) => {
      try {
        const res: AxiosResponse<ApiResponse<TransactionData>> = await transactionService.getAllTokenizedTransaction(
          size,
          page + 1,
          fieldName,
          sortOrder,
          search
        );
        if (res && res.status === 200 && res.data.isSuccess) {
          setRowsData(res?.data?.data?.records);
          setRowCount(res?.data?.data?.paginationInfo?.totalRecords);
        } else if (res) {
          setToast({
            open: Date.now(),
            message: res?.data.message,
            type: "error",
          });
        }
      } catch (error: any) {
        setToast({
          open: Date.now(),
          message: error?.message,
          type: "error",
        });
      }
    }, []);

  // Debounce the function with a delay of 100ms
  const debouncedGetAllData = useCallback(
    debounce((pageSize, page, fieldName, sortOrder, search) => {
      getAllData(pageSize, page, fieldName, sortOrder, search);
    }, 100), 
    [getAllData]
  );
  
  useEffect(() => {
    debouncedGetAllData(size, page, fieldName, sortOrder, filter);
  
    return () => {
      debouncedGetAllData.clear(); // Cleanup debounce on unmount or dependency change
    };
  }, [size, page, fieldName, sortOrder, filter, debouncedGetAllData]);


  return (
    <>
      <Card>
        <CardContent className="table-cardcontent">
          <Datatable
            columns={columns}
            rows={rowsData || []}
            getRowHeight={getRowHeight}
            rowCount={rowCount || 0}           
            setPageSize={setPageSize}
            pageSize={size}
            setPage={setPage}
            page={page || 0}
            searchText={filter}
            setSearchText={setFilter}
            showtoolbar={true}
            onPageChange={(newPage: number) => setPage(newPage)}
            onSortModelChange={handleSortModelChange}
            isSearchOnly={true}
            showCustomerType={true}
          />
        </CardContent>
      </Card>

      <Toast open={toast.open} message={toast.message} type={toast.type} />
    </>
  );
}