import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Tab,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import {
  InteractionRequiredAuthError,
  AccountInfo,
} from "@azure/msal-browser";
import { callMsGraph } from "../utils/MsGraphApiCall";
import ReactApexChart from "react-apexcharts";
import { NavLink } from "react-router-dom";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TransactionService from "../services/transaction";
import {
  TransactionGraphData,
  BarSeriesType,
} from "../utils/types";
import Toast from "../ui-components/Toaster";
import { StatusColors, randomColors } from "../utils/constants";
import pieChartOptions from "../utils/charts/piechart-options";
import barChartOptions from "../utils/charts/barchart-options";
import { groupBy, Item } from "../utils/helpers";
import TransactionList from "../ui-components/Home/Transaction-List";

export default function Home() {
  const [toast, setToast] = useState({ open: 1, message: "", type: "success" });
  const [userName, setUserName] = useState<string | null>();
  const [year, setYear] = React.useState(new Date().getFullYear().toString());
  const [value, setValue] = React.useState("1");
  const { instance } = useMsal();
  const [captureBarData, setCaptureBarData] = useState<Array<BarSeriesType>>([]);
  const [refundBarData, setRefundBarData] = useState<Array<BarSeriesType>>([]);
  const [pieChartSeries, setPieChartSeries] = useState<Array<number>>([]);
  const [pieChartLabels, setPieChartLabels] = useState<Array<string>>([]);
  const [allYears, setAllYears] = useState<string[]>([]);
  const [dataByYear, setDataByYear] = useState<any>(null);
  const [barColors, setBarColors] = useState<string[]>([]);

  const getTransactionOverviewData = useCallback(async () => {
    try {
      const res = await TransactionService.getTransactionOverview();
      if (res && res.status === 200) {
        const data = res?.data?.data?.records;
        const groupByYear = groupBy(data, "year");
        setDataByYear(groupByYear);
        const _AllYears: Array<string> = Object.keys(groupByYear);
        setAllYears(_AllYears);
      } else if (res) {
        setToast({
          open: Date.now(),
          message: res?.data.message,
          type: "error",
        });
      }
    } catch (error: any) {
      setToast({
        open: Date.now(),
        message: error?.message,
        type: "error",
      });
    }
  }, []);

  const getTransactionSummaryData = useCallback(async () => {
    try {
      const res = await TransactionService.getTransactionSummary();
      if (res && res.status === 200) {
        const pieChartData = res?.data?.data?.records;
        const pieChartSeries: Array<number> = [];
        const pieChartLabels: Array<string> = [];
        pieChartData.forEach((item: any) => {
          if (item.applicationName) {
            pieChartSeries.push(parseInt(item.transactionCount));
            pieChartLabels.push(item.applicationName);
          } else {
            pieChartSeries.push(parseInt(item.transactionCount));
            pieChartLabels.push("Other");
          }
        });
        setPieChartSeries(pieChartSeries);
        setPieChartLabels(pieChartLabels);
      } else if (res) {
        setToast({
          open: Date.now(),
          message: res?.data.message,
          type: "error",
        });
      }
    } catch (error: any) {
      setToast({
        open: Date.now(),
        message: error?.message,
        type: "error",
      });
    }
  }, []);

  useEffect(() => {
    if (userName) {
      // Bar Graph API
      getTransactionOverviewData();
      // Pie Chart API
      getTransactionSummaryData();
    }
  }, [userName, getTransactionOverviewData, getTransactionSummaryData]);

  useEffect(() => {
    if (dataByYear) {
      const Captured: Array<TransactionGraphData> = [];
      const Refund: Array<TransactionGraphData> = [];
      let colorsArray: string[] = [];

      dataByYear[year]?.forEach((item: TransactionGraphData) => {
        if (item.type === "Capture") Captured.push(item);
        else Refund.push(item);
      });
      const CapturedGroup = groupBy(Captured, "status");
      const RefundedGroup = groupBy(Refund, "status");
      const CapturedStatusName = Object.keys(CapturedGroup);
      const RefundedStatusName = Object.keys(RefundedGroup);
      let _CaptureGraphData: Array<BarSeriesType> = [];
      let _RefundGraphData: Array<BarSeriesType> = [];
      CapturedStatusName.forEach((status, index) => {
        let data = new Array(12).fill(0);
        if (value === "1") {
          const _statusExist = StatusColors.find(
            (el) => el.statusName === status
          );
          if (_statusExist) {
            colorsArray.push(_statusExist.color);
          } else {
            colorsArray.push(randomColors[index % randomColors.length]);
          }
        }
        CapturedGroup[status].forEach((item: TransactionGraphData) => {
          data[parseInt(item.month) - 1] = parseInt(item.recordCount);
        });
        let obj = {
          name: status,
          data,
        };
        _CaptureGraphData.push(obj);
      });
      RefundedStatusName.forEach((status, index) => {
        let data = new Array(12).fill(0);
        if (value === "2") {
          const _statusExist = StatusColors.find(
            (el) => el.statusName === status
          );
          if (_statusExist) {
            colorsArray.push(_statusExist.color);
          } else {
            colorsArray.push(randomColors[index % randomColors.length]);
          }
        }
        RefundedGroup[status].forEach((item: TransactionGraphData) => {
          data[parseInt(item.month) - 1] = parseInt(item.recordCount);
        });
        let obj = {
          name: status,
          data,
        };
        _RefundGraphData.push(obj);
      });
      setCaptureBarData(_CaptureGraphData);
      setRefundBarData(_RefundGraphData);
      setBarColors(colorsArray);
    }
  }, [year, dataByYear, value]);

  useEffect(() => {
    callMsGraph()
      .then((response) => {
        localStorage.setItem("puser", JSON.stringify(response));
        setUserName(response.displayName);
        window.dispatchEvent(new Event("storage"));
      })
      .catch((e) => {
        if (e instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect({
            ...loginRequest,
            account: instance.getActiveAccount() as AccountInfo,
          });
        }
      });
  }, [instance]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const _handleChange = (event: SelectChangeEvent) => {
    setYear(event.target.value as string);
  };
  
  return (
    <>
      <AuthenticatedTemplate>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Item>
              <Typography
                variant="h6"
                align="center"
                fontSize={20}
                lineHeight={1.5}
              >
                Transactions Summary <br />
                from applications
              </Typography>
              <ReactApexChart
                options={{
                  labels: pieChartLabels,
                  ...pieChartOptions,
                }}
                series={pieChartSeries}
                type="donut"
                height={390}
                className="donut-chart"
              />
            </Item>
          </Grid>
          <Grid item xs={8}>
            <Item className="charts-header">
              <TabContext value={value}>
                <div
                  style={{
                    display: "inline-flex",
                    zIndex: 99,
                    position: "relative",
                    paddingRight: "10px",
                  }}
                >
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="Capture" value="1" />
                    <Tab label="Refund" value="2" />
                  </TabList>
                  <FormControl style={{ width: "100px" }}>
                    <Select value={year} onChange={_handleChange}>
                      {allYears.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <TabPanel value="1">
                  <div className="bar-chart">
                    <ReactApexChart
                      options={{
                        ...barChartOptions,
                        colors: barColors,
                      }}
                      series={captureBarData}
                      type="bar"
                      width="700"
                      height="400"
                    />{" "}
                  </div>
                </TabPanel>
                <TabPanel value="2">
                  {" "}
                  <ReactApexChart
                    options={{
                      ...barChartOptions,
                      colors: barColors,
                    }}
                    series={refundBarData}
                    type="bar"
                    width="700"
                    height="400"
                  />{" "}
                </TabPanel>
              </TabContext>
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item>
              <Grid item xs={12} container sm className="px-2 pt-2 pb-3" spacing={2}>
                <Grid item xs >
                  <Typography align="left" component="h5" fontWeight={700}>
                    Transaction List
                  </Typography>
                </Grid>
                <Grid item>
                  <NavLink
                    style={{ textDecoration: "underline" }}
                    to="/transactions/capture"
                  >
                    View All
                  </NavLink>
                </Grid>
              </Grid>
              <TransactionList setToast={setToast} userName ={userName} />
            </Item>
          </Grid>
        </Grid>
      </AuthenticatedTemplate>
      <Toast open={toast.open} message={toast.message} type={toast.type} />
    </>
  );
}
