export const allCurrency = ["USD", "INR", "Pound"];
export const allStatus = [
  { key: "Authorized", label: "Authorized" },
  { key: "Declined", label: "Declined" },
  { key: "Completed", label: "Completed" },
  { key: "Inprogress", label: "Inprogress" },
  { key: "Cancelled", label: "Cancelled" },
  { key: "Pending", label: "Pending" },
  { key: "Transmitted", label: "Transmitted" },
  { key: "Voided", label: "Voided" },
  { key: "AUTHORIZED_PENDING_REVIEW", label: "Pending Review" },
  { key: "INVALID_REQUEST", label: "Invalid Request" },
];

export const allStatusRefund = [
  { key: "Pending", label: "Pending" },
  { key: "Voided", label: "Voided" },
  { key: "Transmitted", label: "Transmitted" },
  { key: "INVALID_REQUEST", label: "Invalid Request" },
];
export const Merchants = [
  { label: "VCC VC", value: "vcc_vc" },
  { label: "VCC POC", value: "vcc_poc" },
  { label: "VCC VCB", value: "vcc_vcb" },
];

export const Application = ["ALL", "CCDB", "JDE","MAGENTO"];

export const StatusColors = [
  { statusName: "Transmitted", color: "#00ff00" },
  { statusName: "Pending", color: "#0000ff" },
  { statusName: "INVALID_REQUEST", color: "#FFA500" },
  { statusName: "DECLINED", color: "#f70000" },
];

export const randomColors = [
  "#dad21f",
  "#9a3ef6",
  "#d890f9",
  "#6cd4bd",
  "#b6ba6c",
  "#a48348",
  "#7b9fb8",
];

export const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const transactionSearchableFields = [
  "Transaction Id",
  "Amount",
  "Account",
  "Merchant",
  "Customer Email",
  "Customer First name",
  "Customer Last name",
  "Phone number",
  "User",
];

export const USER_ROLES = {
ADMIN:"PaymentsRoleAdmin",
USER:"PaymentsRoleUser"
}


export const ERP = ["SAP", "JDE","MAGENTO"];

export const CustomerTypes = ["WHOLESALE", "RETAIL"];