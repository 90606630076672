// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  Pagination,
  PaginationItem,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
  SelectChangeEvent,
} from "@mui/material";
import {
  allCurrency,
  allStatus,
  allStatusRefund,
  Application,
  Merchants,
  months,
  days,
  transactionSearchableFields,
  CustomerTypes,
} from "../utils/constants";
import React, { useEffect, useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import DescriptionIcon from "@mui/icons-material/Description";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { DataGrid } from "@mui/x-data-grid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CSVLink } from "react-csv";
import TransactionService from "../services/transaction";
import { DataTableProps, MerchantTypes, Search } from "../utils/types";
import { SVGDate } from "./icon-svg/date";
import { SVGExcel } from "./icon-svg/excel";

const QuickSearchToolbar = ({
  setPage,
  searchText,
  setSearchText,
  showtoolbar,
  showcurrency,
  showMerchant,
  showUser,
  showstatus,
  showapplication,
  datepicker,
  transactionType,
  userFilter,
  statuses,
  showExport,
  isSearchOnly,
  showCustomerType,
}: {
  searchText: Search;
  setSearchText: React.Dispatch<React.SetStateAction<Search>>;
  showtoolbar: boolean;
  showcurrency: boolean;
  showMerchant: boolean;
  showUser: boolean;
  showstatus: boolean;
  transactionType: string;
  showapplication: boolean;
  datepicker: boolean;
  rows: any;
}) => {
  const [search, setSearch] = useState<string>(searchText?.searchTerm || "");
  const [fromDate, setFromDate] = useState<any>(searchText?.fromDate);
  const [toDate, setToDate] = useState<any>(searchText?.toDate);
  const [status, setStatus] = React.useState(searchText?.status);
  const [merchant, setMerchant] = React.useState(searchText?.merchant || "");
  const [user, setUser] = React.useState(searchText?.UserId || "");
  const [applicationName, setApplicationName] = React.useState(searchText?.applicationName || "ALL");
  const [currency, setCurrency] = React.useState(searchText?.currency || "");
  const [allData, setAllData] = React.useState("");
  const [error, setError] = useState("");
  const excelRef = useRef();
  const [customerType, setCustomerType] = React.useState(searchText?.customerType || "WHOLESALE");

  function Datepicker() {
    var validateDate = true;
    const [open, setOpen] = React.useState(false);

    const getTimezoneOffset = (value: Date) =>
      value.getTimezoneOffset() * 60000;

    return (
      <>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="From Date"
            inputFormat="MM/DD/YY"
            components={{
              OpenPickerIcon: SVGDate,
            }}
            value={fromDate}
            onClose={() => setOpen(true)}
            onChange={(newValue: any) => {
              setToDate(null);
              if (newValue && newValue?.$d !== "Invalid Date") {
                const dateTime = new Date(newValue);
                const utcFromLocal = new Date(
                  dateTime.getTime() - getTimezoneOffset(dateTime)
                );
                setFromDate(utcFromLocal);
              } else if (newValue?.$d !== "Invalid Date") {
                setFromDate(null);
              }
            }}
            renderInput={(params: any) =>
              fromDate ? (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.inputProps,
                    endAdornment: (
                      <IconButton
                        title="Clear"
                        aria-label="Clear"
                        onClick={() => {
                          setToDate(null);
                          setFromDate(null);
                        }}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                />
              ) : (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )
            }
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="To Date"
            open={open}
            components={{
              OpenPickerIcon: SVGDate,
            }}
            onOpen={() => {
              validateDate = true;
              setToDate(null);
              setOpen(true);
            }}
            inputFormat="MM/DD/YY"
            value={toDate}
            minDate={fromDate}
            onChange={(newValue: any) => {
              if (newValue && newValue?.$d !== "Invalid Date") {
                const dateTime = new Date(newValue);
                const utcFromLocal = new Date(
                  dateTime.getTime() - getTimezoneOffset(dateTime)
                );
                setToDate(utcFromLocal);
                validateDate = false;
              } else if (newValue?.$d !== "Invalid Date") {
                setToDate(null);
              }
            }}
            onClose={() => {
              setOpen(false);
              if (validateDate) {
                setFromDate(null);
              }
            }}
            renderInput={(params: any) =>
              toDate ? (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.inputProps,
                    endAdornment: (
                      <IconButton
                        title="Clear"
                        aria-label="Clear"
                        onClick={() => {
                          setToDate(null);
                          setFromDate(null);
                        }}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                />
              ) : (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )
            }
          />
        </LocalizationProvider>
      </>
    );
  }

  const handleChange = (e: SelectChangeEvent) => {
    const type: string = e.target.name;
    if (type === "currency") {
      setCurrency(e.target.value as string);
    } else if (type === "status") {
      setStatus(e.target.value as string);
    } else if (type === "merchant") {
      setMerchant(e.target.value as string);
    } else if (type === "user") {
      setUser(e.target.value as string);
    }
  };

  const getAllData = async () => {
    setAllData("");
    if (transactionType === "refunded") {
      TransactionService.getAllRefundedTransaction(
        2000,
        1,
        "Id",
        "DESC",
        searchText
      ).then((res: any) => {
        if (res && res.status === 200) {
          setAllData(res.data.data.records);
        } else if (res) {
          setAllData(res?.message);
        }
        if (res.data.data.records.length > 0) {
          setTimeout(() => {
            excelRef.current.link.click();
          }, 500);
        }
      });
    } else {
      TransactionService.getAllTransaction(
        2000,
        1,
        "Id",
        "DESC",
        searchText
      ).then((res: any) => {
        if (res && res.status === 200) {
          setAllData(res.data.data.records);
        } else if (res) {
          setAllData(res?.message);
        }
        if (res.data.data.records.length > 0) {
          setTimeout(() => {
            excelRef.current.link.click();
          }, 500);
        }
      });
    }
  };

  const ExportCSV = () => {
    const d = new Date();
    const prefix = transactionType === "refunded" ? "Refunded_" : "Captured_";
    const file_name =
      prefix +
      "Transaction_" +
      days[d.getDay()] +
      " " +
      months[d.getMonth()] +
      " " +
      d.getDate() +
      " " +
      d.getFullYear() +
      " " +
      d.getHours() +
      "_" +
      d.getMinutes() +
      "_" +
      d.getSeconds();

    const getCSVHeaders = (records: any) => {
      const exportHeaders = [];
      if (records) {
        const [record] = records;
        Object.keys(record).map((key) => {
          if (key !== "customerInfo") {
            exportHeaders.push({ label: key, key });
          }
          return key;
        });
        exportHeaders.push({
          label: "customerFirstName",
          key: "customerInfo.firstName",
        });
        exportHeaders.push({
          label: "customerLastName",
          key: "customerInfo.lastName",
        });
        exportHeaders.push({
          label: "customerEmail",
          key: "customerInfo.email",
        });
        exportHeaders.push({
          label: "customerPhoneNumber",
          key: "customerInfo.phoneNumber",
        });
      }
      return exportHeaders;
    };

    return (
      <>
        <Button
          onClick={getAllData}
          className="export-btn"
          variant="outlined"
          size="small"
        >
          <SVGExcel />
        </Button>
        <CSVLink
          className="export-btn export-btn-hide"
          data={allData}
          headers={getCSVHeaders(allData)}
          filename={file_name}
          ref={excelRef}
        >
          <DescriptionIcon fontSize="small" />
        </CSVLink>
      </>
    );
  };

  function Currency() {
    return (
      <FormControl className="MuiTextField-root">
        <InputLabel id="Currency">Currency</InputLabel>
        <Select
          required
          labelId="Currency"
          id="currency"
          name="currency"
          value={currency}
          label="Currency"
          onChange={handleChange}
          sx={{
            "& .MuiInputLabel-root": {
              lineHeight: 0,
              fontSize: 0,
            },
            "& .MuiSelect-iconOutlined": {
              display: currency ? "none" : "",
            },
            "&.Mui-focused .MuiIconButton-root": { color: "primary.main" },
          }}
          endAdornment={
            currency ? (
              <IconButton
                onClick={() => {
                  setCurrency("");
                }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ) : null
          }
        >
          {!!allCurrency.length &&
            allCurrency.map((data: string) => (
              <MenuItem key={data} value={data}>
                {data}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  }

  function StatusFilter() {
    return (
      <FormControl className="MuiTextField-root">
        <InputLabel shrink id="Status">
          Status
        </InputLabel>
        <Select
          labelId="Status"
          id="status"
          name="status"
          notched
          displayEmpty
          value={status}
          label="Status"
          onChange={handleChange}
          sx={{
            "& .MuiInputLabel-root": {
              lineHeight: 0,
              fontSize: 0,
            },
            "& .MuiSelect-iconOutlined": {
              display: status ? "none" : "",
            },
            "&.Mui-focused .MuiIconButton-root": { color: "primary.main" },
          }}
          endAdornment={
            status ? (
              <IconButton
                onClick={() => {
                  setStatus("");
                }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ) : null
          }
        >
          <MenuItem key="statusall" value="">
            All
          </MenuItem>
          {!!statuses.length &&
            statuses.map((data: string) => (
              <MenuItem key={data.toLowerCase} value={data}>
                {data}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  }

  function MerchantFilter() {
    return (
      <FormControl className="MuiTextField-root">
        <InputLabel shrink id="Merchant">
          Merchant
        </InputLabel>
        <Select
          labelId="Merchant"
          id="merchant"
          name="merchant"
          notched
          displayEmpty
          value={merchant}
          label="Merchant"
          onChange={handleChange}
          sx={{
            "& .MuiInputLabel-root": {
              lineHeight: 0,
              fontSize: 0,
            },
            "& .MuiSelect-iconOutlined": {
              display: merchant ? "none" : "",
            },
            "&.Mui-focused .MuiIconButton-root": { color: "primary.main" },
          }}
          endAdornment={
            merchant ? (
              <IconButton
                onClick={() => {
                  setMerchant("");
                }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ) : null
          }
        >
          <MenuItem key="merchantname" value="">
            All
          </MenuItem>
          {!!Merchants.length &&
            Merchants.map((data: MerchantTypes) => (
              <MenuItem key={data.value} value={data.value}>
                {data.label}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  }

  function UserFilter() {
    return (
      <FormControl className="MuiTextField-root">
        <InputLabel shrink id="User">
          User
        </InputLabel>
        <Select
          labelId="User"
          id="user"
          name="user"
          notched
          displayEmpty
          value={user}
          label="User"
          onChange={handleChange}
          sx={{
            "& .MuiInputLabel-root": {
              lineHeight: 0,
              fontSize: 0,
            },
            "& .MuiSelect-iconOutlined": {
              display: user ? "none" : "",
            },
            "&.Mui-focused .MuiIconButton-root": { color: "primary.main" },
          }}
          endAdornment={
            user ? (
              <IconButton
                onClick={() => {
                  setUser("");
                }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ) : null
          }
        >
          <MenuItem key="user" value="">
            All
          </MenuItem>
          {!!userFilter?.length &&
            userFilter?.map((data: string, index: number) => {
              return (
                data && (
                  <MenuItem key={data + index} value={data}>
                    {data}
                  </MenuItem>
                )
              );
            })}
        </Select>
      </FormControl>
    );
  }

  useEffect(() => {
    if (showtoolbar) {
      let obj: Search = {
        searchTerm: search,
        fromDate: fromDate,
        toDate: toDate,
        merchant: merchant,
        status: status,
        currency: currency,
        applicationName: applicationName,
        customerType: customerType,
        UserId: user
      };
      setSearchText(obj);
    }
  }, [
    search,
    merchant,
    status,
    fromDate,
    toDate,
    currency,
    applicationName,
    customerType,
    setSearchText,
    showtoolbar,
    user,
    isSearchOnly
  ]);

  // Handle application type filter change
  const handleApplicationTypeChange = (event: React.MouseEvent<HTMLElement>, applicationType: string) => {
    setPage(0);
    setApplicationName(applicationType);
  };
  
  // Handle customer type filter change
  const handleCustomerTypeChange = (event: React.MouseEvent<HTMLElement>, customerType: string) => {
    setPage(0);
    setCustomerType(customerType);
  };

  const handleSearchTextChange = (e) => {
    setError("");

    const newValue = e.target.value;
    if (newValue?.trim() !== ""){
      if(newValue.match(/^(?!^\.)[a-zA-Z0-9.@_\- ]*$/)) {
        setSearch(newValue);       
      } else {
        setError("Forbidden character");
      }
    } else {
      setSearch(newValue);
    }
  };

  const HtmlTooltip = ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  );
  return (
    <>
      {showtoolbar && (
        <div className="p-2">
          <div className="d-md-flex justify-content-between align-items-center filter-bar">
            <TextField
              sx={{ backgroundColor: "#E8E8E8" }}
              onChange={handleSearchTextChange}
              helperText={error}
              error={!!error}
              variant="standard"
              value={search}
              placeholder="Search"
              className="common-search"
              InputProps={{
                endAdornment: (
                  <>
                    {!search ? (
                      <SearchIcon fontSize="small" />
                    ) : (
                      <IconButton
                        title="Clear"
                        aria-label="Clear"
                        size="small"
                        style={{
                          visibility: search ? "visible" : "hidden",
                        }}
                        onClick={() => setSearch("")}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    )}
                  </>
                ),
              }}
            />
            <>
              {!isSearchOnly && (
                <HtmlTooltip
                  style={{ marginRight: 5 }}
                  arrow
                  title={
                    <>
                      <b>
                        {
                          "Enter the search keyword to match with either of below fields"
                        }
                      </b>
                      <ul>
                        {transactionSearchableFields.map((field) => {
                          return <li key={field}>{field}</li>;
                        })}
                      </ul>
                    </>
                  }
                >
                  <InfoIcon />
                </HtmlTooltip>
              )}
            </>
            <div className="d-flex justify-content-around w-75">
              {showUser && UserFilter()}
              {showMerchant && MerchantFilter()}
              {showcurrency && Currency()}
              {showstatus && StatusFilter()}
              {datepicker && Datepicker()}
              {showExport && ExportCSV()}
            </div>
          </div>

          <div className="d-flex w-100 align-items-center pt-3">
            {showapplication && (
              <div className="d-flex align-items-center me-3">
                <ToggleButtonGroup
                  value={applicationName}
                  size="small"
                  exclusive
                  fullWidth
                  onChange={handleApplicationTypeChange}
                  aria-label="Platform"
                  sx={{
                    ".Mui-selected": {
                      backgroundColor: "#54585A !important",
                      color: "#ffffff !important",
                      fontWeight: "700 !important",
                    },
                  }}
                >
                  {Application.map((item) => {
                    return (
                      <ToggleButton key={item} value={item}>
                        {item}
                      </ToggleButton>
                    );
                  })}
                </ToggleButtonGroup>
              </div>
            )}

            {showCustomerType && (
              <div className="d-flex w-25 align-items-center">
                <ToggleButtonGroup
                  value={customerType}
                  size="small"
                  exclusive
                  fullWidth
                  onChange={handleCustomerTypeChange}
                  aria-label="Platform"
                  sx={{
                    ".Mui-selected": {
                      backgroundColor: "#54585A !important",
                      color: "#ffffff !important",
                      fontWeight: "700 !important",
                    },
                  }}
                >
                  {CustomerTypes.map((item) => {
                    return (
                      <ToggleButton key={item} value={item}>
                        {item}
                      </ToggleButton>
                    );
                  })}
                </ToggleButtonGroup>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const Datatable = ({
  rows,
  userFilter,
  statuses,
  page,
  columns,
  rowCount,
  pageSize,
  onPageChange,
  setPageSize,
  onSortModelChange,
  setPage,
  setSearchText,
  searchText,
  showtoolbar,
  pagination = true,
  getRowHeight,
  showcurrency,
  showMerchant,
  showUser,
  showstatus,
  showapplication,
  datepicker,
  transactionType,
  showExport,
  isSearchOnly,
  showCustomerType,
}: DataTableProps) => {
  const [columnButtonEl, setColumnButtonEl] =
    React.useState<HTMLButtonElement | null>(null);

  function CustomPagination() {
    return (
      <>
        <div className="PaginationCounter px-2">
          Showing <strong>{Math.min(pageSize * page + 1, rowCount)}</strong> -{" "}
          <strong>{Math.min(pageSize * (page + 1), rowCount)}</strong> from{" "}
          <strong>{rowCount}</strong> data
        </div>{" "}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "60%",
          }}
        >
          {showtoolbar && (
            <FormControl
              sx={{
                ".MuiInputBase-formControl": {
                  width: "90px",
                },
              }}
              style={{ maxWidth: "6rem" }}
            >
              <InputLabel shrink id="demo-simple-select-label">
                Rows
              </InputLabel>
              <Select
                size="small"
                notched
                defaultValue={25}
                value={pageSize}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Rows"
                onChange={(e: any) => {
                  setPageSize(e.target.value);
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          )}
          
          {pagination && (
            <Pagination
              size="medium"
              variant="outlined"
              shape="rounded"
              page={page + 1}
              className="px-2"
              count={Math.ceil(rowCount / pageSize)}
              renderItem={(props2) => <PaginationItem {...props2} />}
              onChange={(event: React.ChangeEvent<unknown>, value: number) =>
                setPage(value - 1)
              }
            />
          )}
        </div>
      </>
    );
  }

  return (
    <DataGrid
      className="data-grid-custom"
      autoHeight
      headerHeight={42}
      rows={rows}
      rowHeight={46}
      getRowHeight={getRowHeight}
      columns={columns}
      page={page}
      rowCount={rowCount}
      components={{ Toolbar: QuickSearchToolbar, Pagination: CustomPagination }}
      componentsProps={{
        panel: {
          anchorEl: columnButtonEl,
        },
        toolbar: {
          setPage,  // Pass setPage here
          setColumnButtonEl,
          searchText,
          setSearchText,
          showtoolbar,
          showcurrency,
          showMerchant,
          showUser,
          showstatus,
          showapplication,
          datepicker,
          transactionType,
          rows,
          userFilter,
          statuses,
          showExport,
          isSearchOnly,
          showCustomerType,
        },
      }}
      disableDensitySelector={true}
      disableColumnMenu={true}
      pageSize={pageSize}
      //onPageChange={onPageChange}
      disableColumnFilter
      //pagination
      enablePagination={true}
      paginationMode="server"
      sortingMode="server"
      onSortModelChange={onSortModelChange}
      sortingOrder={["desc", "asc"]}
      disableSelectionOnClick
    />
  );
};
export default Datatable;
